html {
    padding: 0;
    margin: 0;
}
body {
    padding: 0;
    margin: 0;
    /* background-color: red; */
}

